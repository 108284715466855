@mixin mobile {
    @media (max-width: 576px) {
        @content;
    }
}
@mixin landscapePhone {
    @media (min-width: 576px) {
        @content;
    }
}
@mixin tablets {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin desktops {
    @media (min-width: 992px) {
        @content;
    }
}

html,
body {
    height: 100% !important;
}
.login_left {
    background-color: #464c5b;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include desktops {
        width: 46.125rem;
    }
}
.logo_pic {
    container-type: inline-size;
    line-height: 1;
    width: 38.489583%;
    min-width: 13.125rem;
    max-width: 17.75rem;
    .title {
        font-size: 15.49295774cqw;
        margin-top: 3.52112cqw;
    }
    .text {
        font-size: 7.3943cqw;
    }
}

.login_form {
    width: 20rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.6875rem;
    border-radius: 1.25rem;
}

.login_title {
    color: #ffc76c;
    font-size: 1.8125rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2.8125rem;
    flex-shrink: 0;
}

.vaildImg {
    width: 7.625rem;
    height: 2.875rem;
}

.text_m {
    font-size: 1.125rem;
}

//login
.login-bg-wrapper {
    background: var(--im1, linear-gradient(transparent, transparent)),
        var(--im2, linear-gradient(transparent, transparent));
    background-repeat: no-repeat;
    background-size: cover;
}

.login-bg {
    --im2: url("../images/sd-login-bg.jpg");
}

.backgroundFilter {
    --im1: linear-gradient(to bottom, rgb(252, 248, 237, 0.5), rgba(255, 255, 255, 1));
}
